<template>
    <div>
        <div class="topimage">
            <el-image style="width:100%" :src="require('@/assets/jishu/banner'+(this.$i18n.locale == 'zh' ? '' : '_'+this.$i18n.locale)+'.jpg')"></el-image>
        </div>
        <div class="appmain">
            <el-row :gutter="30">
                <el-col :span="8" v-if="$i18n.locale != 'en'">
                    <div class="item" @click="nav('datadownload')">
                        <div class="jsimage">
                            <el-image :src="require('@/assets/jishu/ziliao.png')"></el-image>
                        </div>
                        <div class="jstitle">{{$t('layout.download')}}</div>
                    </div>
                </el-col>
                <el-col :span="8">
                    <div class="item" @click="nav('productcatalogue')">
                        <div class="jsimage">
                            <el-image :src="require('@/assets/jishu/xuanxing.png')"></el-image>
                        </div>
                        <div class="jstitle">{{$t('layout.model')}}</div>
                    </div>
                </el-col>
                <el-col :span="8">
                    <div class="item" @click="nav('ecucode')">
                        <div class="jsimage">
                            <el-image :src="require('@/assets/jishu/ecu.png')"></el-image>
                        </div>
                        <div class="jstitle">{{$t('layout.ECUfaultcode')}}</div>
                    </div>
                </el-col>
                <el-col :span="8">
                    <div class="item" @click="nav('faqs')">
                        <div class="jsimage">
                            <el-image :src="require('@/assets/jishu/wenti.png')"></el-image>
                        </div>
                        <div class="jstitle">{{$t('layout.FAQ')}}</div>
                    </div>
                </el-col>
                <el-col :span="8" >
                    <div class="item" @click="nav('feedback')">
                        <div class="jsimage">
                            <el-image :src="require('@/assets/jishu/fankui.png')"></el-image>
                        </div>
                        <div class="jstitle">{{$t('layout.Productfeedback')}}</div>
                    </div>
                </el-col>
            </el-row>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return{
            title:''
        }
    },
    created () {
        this.title = this.$t('layout.technical')+'-'+this.$t('seo.title')
    },
    metaInfo () {
        return {
        title: this.title,
        meta: [
            {
                name: 'keyWords',
                content: this.$t('seo.keywords')
            },
            {
                name: 'description',
                content: this.$t('seo.description')
            }
        ]
        }
    },
    methods:{
        nav(item){
            this.$router.push('/'+item,'_blank')
        }
    }
}
</script>
<style lang="scss" scoped>
    .appmain{
        width: 60%;
        margin: 0 auto;
    }
    @media screen and (max-width: 480px) {
        .appmain{
            width: 100%;
            .item{
                height: 150px;
                margin-top: 20px;
                padding-top: 20%;
            }
        }
        
    }

    .item{
        height: 242px;
        margin-top: 20px;
        padding-top: 16%;
        cursor: pointer;
        background: #fff;
        .jsimage{
            width: 70px;
            height: 70px;
            margin: 0 auto;
        }
        .jstitle{
            margin-top: 15px;
            text-align: center;
            font-family: SourceHanSansCN-Bold;
            font-weight: bold;
            color: #333333;
        }
    }
</style>